@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200..1000;1,200..1000&display=swap');
@layer base {
  :root {
    --background: #E9EEF4;
    --primary: #284D79;
    --border-checkbox: #284D79;
    --border: #317FC8;
    --border2: #317FC8;
    --btn: #FFC842;
    --black: #000;
    --grayBlue: #95A9DD;
    --gray: #50555C;
    --width-container: 428px;
    --family: 'Nunito',sans-serif;
    --radius: 12px;
    --bg-input: #00000000;
    --gradient: linear-gradient(288deg, #317FC8 0%, #0044B8 100.22%);
    --mark: #A90C54;
    --gap: 24px;
    --inputWeight: 600;
    --checked: white;
    --border-checked: #317FC8;
  }
 
}
.white {
  --checked: #95A9DD;
  --border-checkbox: #95A9DD;
  --border: white;
  --bg-input: white;
  --gap: 32px;
  --inputWeight: 700;
  --border-checked: #95A9DD;
  label {
    @apply  font-bold;

  }

}
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-primary min-h-full w-full overflow-x-hidden flex justify-center;
  }
  h1 {
    @apply text-lg font-bold;
  }
  h2 {
    @apply text-xl text-center text-black pt-4 mb-6 font-bold;
  }
  p {
    @apply text-base text-center font-semibold;
  }
  label {
    @apply text-base block mb-2 mt-8 font-semibold;

  }
  #root {
    @apply flex max-w-content mx-auto  w-full;
  }
}
.step-list {
  position: relative;
  padding-left: 28px;
  box-sizing: border-box;
  &:before {
    position: absolute;
    content: '';
    top: 50%;
    transform: translateY(-50%);
    width: 6px;
    height: 6px;
    border-radius: 50%;
    left: 9px;
    background-color: var(--btn);
  }
  &.played {
    img {
      opacity: 1;
    }
    &:before {
      opacity: 0;
      transition: .3s all;
    }
  }
}
html {
  font-family: var(--family);
}

.paddle-frame{
  position: static !important;
}

.border-gradient {
  position: relative;
  background-color: transparent;

  &:before {
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-image: linear-gradient(287.89deg, #317FC8 0%, #0044B8 100.22%);
    border-radius: .75rem;
    z-index: -1;
  }
  &::after {
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    background-color: white;
    border-radius: .75rem;
    z-index: -1;
  }
}
.link-decoration {
  a {
    text-decoration: underline;
  }
}

.btn-large {
  font-size: 22px;
  color: black;
  line-height: 1;
  font-weight: 700;
}

.btn-close {
  background-color: transparent;
  width: 24px;
  height: 24px;
  padding: 0;
  position: absolute;
  top: 28px;
  &:before,
  &:after {
    position: absolute;
    content: '';
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%) rotate(45deg);
    width: 9px;
    height: 1px;
    background-color: black;
  }
  &:after {
    transform: translate(-50%,-50%) rotate(-45deg);
  }
}

.spinner{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height : 50px;
    width : 50px;
    border-radius : 50%;
    border : 10px solid #95A9DD;
    border-top-color : #284D79;
    animation : spin 1s linear infinite;
  }

.submit{
  opacity: 1 !important;
}
.submit:disabled{
  opacity: 0.8 !important;
  cursor: not-allowed;
}
.apple-pay{
  background: #000;
}
  
  @keyframes spin{
    0%{
      transform :translate(-50%, -50%) rotate(0deg);
    }
    100%{
      transform :translate(-50%, -50%) rotate(360deg);
    }
  }

  .show-popup {
    transform: translateY(100%);
    &.active {
      transform: translateY(0);
    }
  }